import Dropzone from "dropzone";
import { Controller } from "stimulus";
import { DirectUpload } from "activestorage";
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "helpers";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
    var myDropzone = this.dropZone;
    var json_obj = JSON.parse(myDropzone.options.preloadImages);
    

    var ArrIt = json_obj["images"];
    if (!jQuery.isEmptyObject(ArrIt)){
      for(var i = 0; i < ArrIt.length; i++) {
          var obj = ArrIt[i];
          console.log(obj);
          var mockFile = { name: obj["name"], size: obj["size"], id: obj["id"] };
          myDropzone.options.addedfile.call(myDropzone, mockFile);
          myDropzone.options.thumbnail.call(myDropzone, mockFile, obj["url"]);
          myDropzone._updateMaxFilesReachedClass();
      }
    }

    if ($("#preview_cover").text().length > 0) {
      $('#add_cover_btn').hide();
    } else {
      $('#add_cover_btn').show();
    }
  
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      setTimeout(() => {
        file.accepted && createDirectUploadController(this, file).start();
      }, 500);
      if ($("#preview_cover").text().length > 0) {
        $('#add_cover_btn').hide();
      } else {
        $('#add_cover_btn').show();
      }
    });

    this.dropZone.on("queuecomplete", function (file) {
      $("#image_placeholder").addClass('d-none');
      $("button").prop("disabled", false);
      $("#draft_btn").prop("disabled", false);
    });

    this.dropZone.on("removedfile", file => {
      if(this.dropZone.options.coverInput == "false"){
        $.post('/d/' + file.id);
        //alert('deleted');
      }
      //alert(this.dropZone.options.coverInput);
      file.controller && removeElement(file.controller.hiddenInput);
      if ($("#preview_cover").text().length > 0) {
        $('#add_cover_btn').hide();
      } else {
        $('#add_cover_btn').show();
      }
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on("error", (file, message) => { 
      alert(message);
      removeElement(file.hiddenInput);
      removeElement(file.previewElement);
      if ($("#preview_cover").text().length > 0) {
        $('#add_cover_btn').hide();
      } else {
        $('#add_cover_btn').show();
      }
    });
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }

  get previewDiv() {
    return this.data.get("previewDiv");
  }

  get preloadImages() {
    return this.data.get("preloadImages");
  }

  get coverInput() {
    return this.data.get("coverInput");
  }

}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  makeid(length){
     var result           = '';
     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
     var charactersLength = characters.length;
     for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
     }
     return result;
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    $("." + this.slider_class).find('.progress-bar').width(progress + "%");

    //findElement(
    //  this.file.previewTemplate,
    //  ".dz-upload"
    //).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
    this.sliderTemplate = $('[data-role="slider-template"]');
    this.slider_class = this.makeid(20);
    this.content = this.sliderTemplate.children().clone(true, true).addClass(this.slider_class);
    $("#image_placeholder").removeClass('d-none');
    $("#image_status").append(this.content);
    $("button").prop("disabled", true);
    $("#draft_btn").prop("disabled", true);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
    $("." + this.slider_class).hide();
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    //headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    previewsContainer: controller.previewDiv,
    autoQueue: false,
    previewTemplate: document.querySelector('#tpl').innerHTML,
    thumbnailWidth: '1000',
    thumbnailHeight: '1000',
    preloadImages: controller.preloadImages,
    acceptedFiles: "image/jpeg,image/png,image/gif,image/jpg",
    coverInput: controller.coverInput
  
  });
}

